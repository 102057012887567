<template>
	<div>
		<div style="margin: 10px 0">
			<el-input style="width: 200px" placeholder="请输入名称" suffix-icon="el-icon-search" v-model="name"></el-input>
			<el-select v-model="state">
				<el-option lable="待处理" value="待处理"></el-option>
				<el-option lable="已结束" value="已结束"></el-option>
				<el-option lable="已报名" value="已报名"></el-option>
			</el-select>
			<el-button class="ml-5" type="primary" @click="load">搜索</el-button>
			<el-button type="warning" @click="reset">重置</el-button>
		</div>
		<el-table :data="tableData" border stripe :header-cell-class-name="'headerBg'">
			<el-table-column prop="bdid" label="标的编号" width="165"></el-table-column>
			<el-table-column prop="gsname" label="公司名称"></el-table-column>
			<el-table-column prop="biaodiname" label="标的时段/计划编号" width="180"></el-table-column>
			<el-table-column prop="pname" label="标的物"></el-table-column>
			<el-table-column prop="start" label="起点"></el-table-column>
			<el-table-column prop="end" label="终点"></el-table-column>
			<el-table-column prop="danwei" label="计量单位"></el-table-column>
			<el-table-column prop="lanbiaojia" label="拦标价"></el-table-column>
			<el-table-column prop="remark" label="应标数量"></el-table-column>
			<el-table-column prop="baojia" label="我的报价"></el-table-column>
			<el-table-column prop="state" label="标的状态"></el-table-column>
			<el-table-column label="操作" width="280" align="center">
				<template slot-scope="scope">
					<el-button type="success" @click="handleEdit(scope.row)"><i class="el-icon-search"></i>查看</el-button>
						<el-popconfirm v-if="scope.row.state=='已报价'" title="是否确定撤销报价?"  @confirm='chexiaobaojia(scope.row)'>
							<el-button type="danger"  slot="reference" style="z-index: 9999 !important;">撤销报价</el-button>
						</el-popconfirm>
					<el-button type="info" @click="showms(scope.row.bdid)"><i class="el-icon-search"></i>查看公告</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div style="padding: 10px 0">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum"
				:page-sizes="[2, 5, 10, 20]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
				:total="total">
			</el-pagination>
			<el-dialog title="详情" :visible.sync="dialogFormVisible" width="70AA%">
				<div>
					<el-steps :active="current">
						<el-step title="招标邀请"></el-step>
						<el-step title="入围"></el-step>
						<el-step title="商务谈判"></el-step>
						<el-step title="中标"></el-step>
					</el-steps>
					<div v-if="current==0" class="steps-content" style="margin-top: 10px;">
						<el-descriptions title="" direction="vertical" :column="4" border>
							> <el-descriptions-item label="邀请状态">待处理</el-descriptions-item>
						</el-descriptions>
						<el-descriptions title="" direction="vertical" :column="4" border>
							> <el-descriptions-item label="招标单位">新疆天利捷通物流有限公司</el-descriptions-item>
							<el-descriptions-item label="招标项目">{{this.form1.name}}</el-descriptions-item>
							<el-descriptions-item label="招标负责人" :span="2">{{this.form1.fuzeren}}</el-descriptions-item>
							<el-descriptions-item label="联系方式">{{this.form1.phone}}</el-descriptions-item>
							<el-descriptions-item label="发布日期">{{this.form1.fabuTime}}</el-descriptions-item>
							<el-descriptions-item label="拦标价">{{this.form1.lanbiaojia}}</el-descriptions-item>
						</el-descriptions>
						<el-button type="success" style="margin-top: 15px;" @click="jieshou">接受邀请</el-button>
						<el-popconfirm title="是否确定拒绝邀请?"  @confirm='jujue'>
						  	<el-button type="info" slot="reference" style="z-index: 9999 !important;">拒绝邀请</el-button>
						</el-popconfirm>
					
					</div>
					<div v-if="current==1" class="steps-content">
						<div class="container" style="min-height: 100%; padding-bottom: 10px;">
						    <el-table :data="tableData1" style="width: 100%">
						     <el-table-column  prop="name" label="项目名称"></el-table-column>
						     <el-table-column  prop="guige"label="规格型号"></el-table-column>
						     <el-table-column  prop="shuliang" label="采购数量"></el-table-column>
							 <el-table-column  prop="danwei" label="计量单位"></el-table-column>
							 <el-table-column  label="报价"><el-button @click="shuruboajia">点击输入报价和应标数量</el-button></el-table-column>
						    </el-table>
						  </div>
						<div v-if="this.form1.file!=null">
							<el-button type="success" @click="download()">点击下载标书</el-button>
						</div>
						<div v-if="this.form1.file==null">
							<el-button type="success"  @click="showms('')">查看公告</el-button>
						</div>
						
						
					</div>
					<div v-if="current==2" class="steps-content">
						<div>
							<el-descriptions title="提示" direction="vertical" :column="4" border>
								> <el-descriptions-item label="提示:">线下正在评标,请耐心等待!</el-descriptions-item>
							</el-descriptions>
						</div>
					</div>
					<div v-if="current==4" class="steps-content">
						<div>
							<el-descriptions title="提示" direction="vertical" :column="4" border>
								> <el-descriptions-item label="提示:">您已拒绝参标!</el-descriptions-item>
							</el-descriptions>
						</div>
					</div>
					
					<div v-if="current==3" class="steps-content">
						<div v-if="form.istrue=='是'">
							<el-descriptions title="" direction="vertical" :column="4" border>
								> <el-descriptions-item label="中标结果">{{this.form.state}}</el-descriptions-item>
							</el-descriptions>
							<el-descriptions title="" direction="vertical" :column="4" border>
								> <el-descriptions-item label="中标通知">
									{{this.user.username}}:在我单位组织的{{this.form.biaodiname}}(招标编号:{{this.form.id}})中,经综合评审,贵公司被评定为中标单位,招标负责人:{{this.form1.fuzeren}},电话:{{this.form1.phone}}
								</el-descriptions-item>
							</el-descriptions>
						</div>
						<div v-else>
							<el-descriptions title="" direction="vertical" :column="4" border>
								> <el-descriptions-item label="中标结果">{{this.form.state}}</el-descriptions-item>
							</el-descriptions>
							<el-descriptions title="" direction="vertical" :column="4" border>
								> <el-descriptions-item label="中标通知">
									{{this.user.username}}:在我单位组织的{{this.form.biaodiname}}(招标编号:{{this.form.id}})中,经综合评审,贵公司未中标
								</el-descriptions-item>
							</el-descriptions>
						</div>
					</div>
				</div>
				<div slot="footer" class="dialog-footer">
					<div v-if="this.form.state=='已拒绝'||this.form.state=='已报价'||this.form.state=='已结束'">
						
					</div>
					<div v-else>
						<el-popconfirm :title="title"  @confirm='baojia'>
						    <el-button type="primary" slot="reference" style="margin-left: 4px;">提交</el-button>
						</el-popconfirm>
					</div>
					<el-button @click="dialogFormVisible = false">取 消</el-button>
				</div>
			</el-dialog>
			<el-dialog title="报价输入" :visible.sync="dialogForm2Visible" width="20%" >
					数量(车):<el-input type="number"  v-model="num"></el-input>
					报价(元): <el-input type="number"  v-model="baojianum"></el-input>
			  <div slot="footer" class="dialog-footer">
			    <el-button type="primary" @click="dialogForm2Visible = false">确 定</el-button>
				<el-button @click="dialogForm2Visible = false">取 消</el-button>
			  </div>
			</el-dialog>
			<el-dialog title="标的公告" :visible.sync="dialogForm1Visible" width="50%" >
					 <vue-editor v-model="this.form1.gonggao" disabled="true"></vue-editor>
			  <div slot="footer" class="dialog-footer">
			    <el-button type="primary" @click="dialogForm1Visible = false">确 定</el-button>
			  </div>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import { VueEditor } from "vue2-editor";
	export default {
		name: "Biaodi",
		components: {
		    VueEditor
		},
		data() {
			return {
				tableData: [],
				tableData1:[],
				title:"请仔细检查报价和数量,检查无误请点击确定提交!",
				total: 0,
				pageNum: 1,
				pageSize: 10,
				name: "",
				form: {},
				form1: {},
				dialogFormVisible: false,
				dialogForm1Visible: false,
				dialogForm2Visible: false,
				multipleSelection: [],
				user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
				current: 0,
				biaodiName: 0,
				gonggao:"",
				baojianum:"",
				num:"",
				state:''
			}
		},
		created() {
			this.load()
		},
		methods: {
			change(value){
				this.$forceUpdate();
			},
			load() {
				this.request.get("/biaodigl/page", {
					params: {
						pageNum: this.pageNum,
						pageSize: this.pageSize,
						name: this.name,
						gsname: this.user.username,
						state: this.state
					}
				}).then(res => {
					this.tableData = res.data.records
					this.total = res.data.total
				})
			},
			handleEdit(row) { //查看标的状态
			    this.form={};
				this.dialogFormVisible = true
				this.form = JSON.parse(JSON.stringify(row))
				if (this.form.state == "已报名") {
					this.current = 1;
				} else if (this.form.state == "已结束") {
					this.current = 3;
				} else if (this.form.state == "已报价") {
					this.current = 2;
				}else if (this.form.state == "已拒绝") {
					this.current = 4;
				}
				this.biaodiName = this.form.biaodiname
				this.request.get("/biaodi/findBy", {
					params: {
						name: this.biaodiName,
					}
				}).then(res => {
					this.form1 = res.data[0]
				})
				this.tableData1=[{
					name:this.form1.name,
					guige:this.form1.guige,
					shuliang:this.form1.shuliang,
					danwei:this.form1.danwei,
					remark:this.form1.remark
				}]
				console.log(this.tableData1.length)
				
			},
			showms(row){
				if(row!=""){
					this.bdid = row
					this.request.get("/biaodi/findBy", {
						params: {
							id: this.bdid,
						}
					}).then(res => {
						this.form1 = res.data[0]
					})
				}
				this.dialogForm1Visible = true
				console.log(this.form1.gonggao)
			},
			reset() {
				this.name = ""
				this.load()
			},
			handleSizeChange(pageSize) {
				this.pageSize = pageSize
				this.load()
			},
			handleCurrentChange(pageNum) {
				this.pageNum = pageNum
				this.load()
			},
			handleFileUploadSuccess(res) {
				this.form.file = res
			},
			handleImgUploadSuccess(res) {
				this.form.img = res
			},
			download(url) {
				window.open(url)
			},
			exp() {
				window.open("http://localhost:9091/biaodi/export")
			},
			handleExcelImportSuccess() {
				this.$message.success("导入成功")
				this.load()
			},
			changeState(obj) {
				this.entity = obj;
				this.save();
			},
			jieshou() {
				this.form.state = "已报名";
				this.request.post("/biaodigl", this.form).then(res => {
					if (res.code === '200') {
						this.$message.success("保存成功")
						this.dialogFormVisible = false
						this.load()
					} else {
						this.$message.error("保存失败")
					}
				})
			},
			jujue() {
				this.form.state = "已拒绝";
				this.request.post("/biaodigl", this.form).then(res => {
					if (res.code === '200') {
						this.$message.success("保存成功")
						this.dialogFormVisible = false
						this.load()
					} else {
						this.$message.error("保存失败")
					}
				})
			},
			baojia() {
				if(this.baojianum==""||this.baojianum==undefined){
					this.$message.error("请输入报价")
				}else if(this.num==""||this.num==undefined){
					this.$message.error("请输入应标数量")
				}
				// else if(parseInt(this.num)>parseInt(this.form1.shuliang)){
				// 	this.$message.error("应标数量不能大于采购数量")
				// }
				else{
					this.form.state = "已报价";
					this.form.baojia=this.baojianum
					this.form.remark=this.num
					this.request.post("/biaodigl", this.form).then(res => {
						if (res.code === '200') {
							this.$message.success("保存成功")
							this.dialogFormVisible = false
							this.load()
						} else {
							this.$message.error("保存失败")
						}
					})
				}
				
			},download(){
				window.open(this.form1.file)
			},shuruboajia(){
				this.dialogForm2Visible =true
				
			},chexiaobaojia(row){
				this.form = JSON.parse(JSON.stringify(row))
				this.form.state = "已报名";
				this.form.baojia=0;
				this.form.baojianum=0;
				console.log(this.form)
				this.request.post("/biaodigl", this.form).then(res => {
					if (res.code === '200') {
						this.$message.success("报价撤销成功")
						this.dialogFormVisible = false
						this.load()
					} else {
						this.$message.error("报价撤销失败")
					}
				})
				
			}
		}
	}
</script>


<style>
	.headerBg {
		background: #eee !important;
	}
</style>
